<script setup>
import { ref } from "vue"
import { useConfigStore } from "@/store/config"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"

const configStore = useConfigStore()
const confirm = useConfirm()
const toast = useToast()

const loading = ref(false)

const activityActive = ref({
  activity_id: null,
  bot_active: false,
  leaderboard_active: false,
  stage: 'initial', // initial, add_activity_id, bot_active, leaderboard_active, finishing, finished
})

const confirmFinished = () => {
  confirm.require({
    message: 'Are you sure you want to finish?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-danger',
    rejectClass: 'p-button-secondary',
    accept: () => {
      activityActive.value.stage = 'finishing'
      update()
    }
  })
}

const confirmRestart = () => {
  confirm.require({
    message: 'Are you sure you want to restart?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-success',
    rejectClass: 'p-button-secondary',
    accept: () => {
      update()
    }
  })
}

const query = () => {
  loading.value = true

  configStore.show('uhas_activity_active').then(({ data }) => {
    if (! data.error) {
      const datas = data.config.value || []

      activityActive.value.activity_id = datas.activity_id ? datas.activity_id : null
      activityActive.value.bot_active = datas.bot_active ? datas.bot_active : false
      activityActive.value.leaderboard_active = datas.leaderboard_active ? datas.leaderboard_active : false
      activityActive.value.stage = datas.stage ? datas.stage : 'initial'

      activityActive.value.leaderboard_show = datas.leaderboard_show ? datas.leaderboard_show : false
      activityActive.value.stats_show = datas.stats_show ? datas.stats_show : false
      activityActive.value.robot_score = datas.robot_score ? datas.robot_score : false

      loading.value = false
    }
  })
}

const update = () => {
  loading.value = true

  configStore.update('uhas_activity_active', { value: activityActive.value }).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success (#uhas_activity_active)', life: 3000})

    query()

  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})

  }).finally(() => {
    loading.value = false
  })
}

query()

</script>

<template>
  <div class="block-section mb-3">
    <div class="block-header mb-2">
      <span class="block-title">
        <div>Activity Active</div>
      </span>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 border-round w-full lg:w-6">
          <div class="p-fluid">
            <div class="card">
              <label class="block text-900 font-medium mb-2" for="activity_id">Activity ID</label>
              <InputNumber
                v-model="activityActive.activity_id"

                :disabled="activityActive.stage != 'initial'"
                id="activity_id"
              />

              <div class="mb-3" />
              
              <label class="block text-900 font-medium mb-2" for="bot_active">Bot Active</label>
              <InputSwitch
                v-model="activityActive.bot_active"
                
                :disabled="activityActive.stage != 'add_activity_id'"
                id="bot_active"
              />

              <div class="mb-3" />

              <label class="block text-900 font-medium mb-2" for="leaderboard_active">Leaderboard Active</label>
              <InputSwitch
                v-model="activityActive.leaderboard_active"
                
                :disabled="activityActive.stage != 'bot_active'"
                id="leaderboard_active"
              />
              
              <div class="mb-3" />

              <ToggleButton v-model="activityActive.leaderboard_show" :disabled="activityActive.stage != 'leaderboard_active'" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" onLabel="Leaderboard Show" offLabel="Leaderboard Hide" />
              <div class="mb-1" />
              <ToggleButton v-model="activityActive.stats_show" :disabled="activityActive.stage != 'leaderboard_active'" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" onLabel="Stats Show" offLabel="Stats Hide" />
              <div class="mb-1" />
              <ToggleButton v-model="activityActive.robot_score" :disabled="activityActive.stage != 'leaderboard_active'" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" onLabel="Robot Score Online" offLabel="Robot Score Offline" />

              <div class="mb-3" />

              <Button
                class="w-full p-button-danger"
                :disabled="activityActive.stage != 'leaderboard_active'"
                label="Finish Activity"
                :loading="activityActive.stage == 'finishing'"

                @click="confirmFinished"
              />

              <div class="mb-3" />

              <Button
                class="w-full p-button-success"
                :disabled="activityActive.stage != 'finished'"
                label="Restart Activity"

                @click="confirmRestart"
              />
            </div>
          </div>

          <br/>

          <Button
            class="w-full"
            :disabled="! ['initial', 'add_activity_id', 'bot_active', 'leaderboard_active'].includes(activityActive.stage)"
            label="Save"
            :loading="loading"

            @click="update"
          />
        </div>
      </div>
    </div>
  </div>
</template>
